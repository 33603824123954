<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 300px;">
          <label>点评日期</label>
          <range-date @change="handleDateChange" ref="createTime"></range-date>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.city"
              placeholder="城市"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.companyName"
              placeholder="公司名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin: 0 30px 0 10px" @click="reset">重置</a-button>

        <a-button type="success"  @click="exprotExccel">导出</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
    </a-table>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import RangeDate from '@/components/datetime/RangeDate'

export default {
  name: "PostCompanyList",
  components: {RangeDate},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "company/postCompanyList",
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "公司ID",
          width: 100,
          align: "center",
          dataIndex: "companyId",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'companyId' && sortedInfo.order
        },
        {
          title: "城市",
          width: 120,
          align: "center",
          dataIndex: "city",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'city' && sortedInfo.order
        },
        {
          title: "公司名称",
          width: 220,
          align: "center",
          dataIndex: "companyName",
        },
        {
          title: "点评次数",
          width: 120,
          align: "center",
          dataIndex: "postCount",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'postCount' && sortedInfo.order
        },
        {
          title: "最后点评时间",
          width: 170,
          align: "center",
          dataIndex: "lastPostDate",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'lastPostDate' && sortedInfo.order
        },
        {}
      ];
    },
  },

  mounted() {
    this.fetch();
  },
  methods: {
    handleDateChange(value) {
      if (value) {
        this.queryParams.lastPostDate = value[0] + " 00:00:00"
        this.queryParams.lastPostDate2 = value[1] + " 23:59:59"
      }
    },

    exprotExccel() {
      let {sortedInfo, filteredInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.$export('company/exportPostCompanyList', {
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo
      })
    },
  }
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
